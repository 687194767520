import { find, max } from 'lodash/fp';

async function getMaxUpdatedAt(table, userId) {
    // Dexie can use an index for sorting or filtering,
    // but not both.  Since chances are that all the records
    // have the same userId, we're going to use the index for
    // ordering and then do the filtering ourselves.
    const keys = await table.orderBy('[user_id+updated_at]').reverse().keys();

    const record = find({
        0: userId,
    })(keys);

    return record ? record[1] : null;
}

export default async function getProgressMaxUpdatedAt(userId, db) {
    // If there is a record in progressFetches for this user, that
    // means that we have fetched progress for them in the past, so
    // we can determine the progressMaxUpdatedAt.  Without this,
    // we could not tell the difference between a user with no progress
    // and a user for whom we have not yet fetched progress.
    const progressFetch = await db.progressFetches.where({ user_id: userId }).first();

    if (!progressFetch) return undefined;

    const updatedAts = [
        await getMaxUpdatedAt(db.lessonProgress, userId),
        await getMaxUpdatedAt(db.streamProgress, userId),
        await getMaxUpdatedAt(db.projectProgress, userId),
    ];

    return max(updatedAts) || 0;
}
