/*
    NOTE: We have this current user interceptor in the StoredCurrentUser module
    as well as a current user interceptor in the Users module. The important
    distinction between these two interceptors is that the interceptor in this
    file is part of the front royal store interceptor logic responsible for
    persisting requested changes to the store prior to the API request getting
    triggered for offline mode parity, whereas the other is responsible for
    handling responses to API requests while online.
*/

import buildApiResponse from 'buildApiResponse';
import isCurrentUserUpdateApiCall from './isCurrentUserUpdateApiCall';

async function handleRequest({ config, $injector }) {
    // `config.data` should be a regular object in this context and not an instance of FormData,
    // so we can reference the record directly and avoid having to use the FormData API.
    const record = config.data.record;
    record.synced_to_server = 0;

    const frontRoyalStore = $injector.get('frontRoyalStore');
    await frontRoyalStore.updateCurrentUser(record.id, record);

    // This is responsible for triggering the API request and storing
    // changes from the server back to the client db.
    await frontRoyalStore.flush();

    // Don't include the user record in the mocked response. This code is asynchronous,
    // so it's theoretically possible that we might accidentally overwrite a changed
    // property value if we were to include the user record in the mocked response
    // since it would contain stale values. Probably not a practical issue though
    // since this code runs most likely runs fast enough for it not to be a problem.
    return buildApiResponse(config);
}

export default async function currentUserRequestInterceptor(config, $injector) {
    if (!isCurrentUserUpdateApiCall(config, $injector)) {
        return null;
    }

    // Don't intercept when we're trying to send results to the server
    if (config.data.get && JSON.parse(config.data.get('meta'))?.flushing_front_royal_store) {
        return null;
    }

    return () =>
        handleRequest({
            config,
            $injector,
        });
}
