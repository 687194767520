import buildApiResponse from 'buildApiResponse';
import getVersionId from 'getVersionId';
import isPresent from 'isPresent';
import { type FrontRoyalStore, type LessonProgressRecord, type StreamProgressRecord } from 'FrontRoyalStore';
import { type IRequestConfig, type auto } from 'angular';
import { type AnyObject } from '@Types';

type HandleRequestParams = {
    lessonProgress: LessonProgressRecord;
    config: IRequestConfig;
    $injector: auto.IInjectorService;
};

async function handleRequest({ lessonProgress, config, $injector }: HandleRequestParams) {
    const frontRoyalStore = $injector.get<FrontRoyalStore>('frontRoyalStore');
    const [responseRecord, meta] = await saveLessonAndStreamProgress(frontRoyalStore, lessonProgress, config);

    return buildApiResponse(
        config,
        {
            lesson_progress: [responseRecord],
        },
        meta,
    );
}

async function saveLessonAndStreamProgress(
    frontRoyalStore: FrontRoyalStore,
    lessonProgress: LessonProgressRecord,
    config: IRequestConfig,
) {
    let streamMeta;
    let streamLocalePackId;
    // We can do [0] because the client only ever sends up
    // one stream progress at a time.  The api supports sending
    // multiples for when the store is flushed.  See flushStoredLessonProgress
    const streamProgress = (config?.data?.meta?.stream_progress_records as StreamProgressRecord[])?.[0];

    if (streamProgress) {
        streamMeta = await saveStreamProgress(frontRoyalStore, streamProgress);
        streamLocalePackId = streamMeta.lesson_streams_progress.locale_pack_id;
    }

    const record =
        (await frontRoyalStore.retryOnHandledError(db =>
            db.lessonProgress
                .where('[user_id+locale_pack_id]')
                .equals([lessonProgress.user_id, lessonProgress.locale_pack_id])
                .first(),
        )) || lessonProgress;

    // The logic here matches what is on the server in LessonProgress::create_or_update
    ['frame_bookmark_id', 'frame_history', 'frame_durations', 'complete'].forEach(key => {
        // FIXME: TS knows that the value for these keys are different types, so it thinks you could be doing
        // something like record['completed_at'] = lessonProgress['complete'] which have different types.
        record[key as 'complete'] = lessonProgress[key as 'complete'];
    });

    const now = new Date().getTime() / 1000;
    if (record) {
        mergeCompletedFrames(record, lessonProgress.completed_frames);
        mergeChallengeScores(record, lessonProgress.challenge_scores);
    }

    if (isPresent(lessonProgress.best_score) && lessonProgress.best_score >= (record.best_score || 0)) {
        record.best_score = lessonProgress.best_score;
    }

    if (!record.created_at) {
        record.created_at = now;
    }

    record.started_at = record.started_at || now;
    record.completed_at = lessonProgress.complete && !record.completed_at ? now : record.completed_at;
    record.last_progress_at = now;
    record.synced_to_server = 0;
    record.fr_version = getVersionId();

    // When we flush the lesson progress to the server, we have to bundle
    // the stream up with the lesson
    if (streamLocalePackId) {
        // In almost all cases, each lesson progress record should
        // be associated with one stream_locale_pack_id, but conceptually
        // there could be more.
        if (!record.linked_stream_locale_pack_ids) {
            record.linked_stream_locale_pack_ids = [];
        }
        if (!record.linked_stream_locale_pack_ids.includes(streamLocalePackId)) {
            record.linked_stream_locale_pack_ids.push(streamLocalePackId);
        }
    }

    await frontRoyalStore.retryRequestOnHandledError('saveProgress', {
        table: 'lessonProgress',
        records: [record],
    });

    // FIXME: we should just return modifications (this is post-MVP, when we switch to
    // optimistic locking.  For now
    // we should be able to avoid changing the logic in lesson_progress.js#_save)
    return [record, streamMeta];
}

// This is the same logic as the server-side method of the same name.
function mergeCompletedFrames(existingRecord: LessonProgressRecord, incomingCompletedFrames: AnyObject) {
    if (!incomingCompletedFrames) return;

    if (!Object.keys(incomingCompletedFrames).length && !existingRecord.for_test_lesson) {
        existingRecord.completed_frames = {};
        return;
    }

    existingRecord.completed_frames = {
        ...existingRecord.completed_frames,
        ...incomingCompletedFrames,
    };
}

// This is the same logic as the server-side method of the same name.
function mergeChallengeScores(existingRecord: LessonProgressRecord, incomingChallengeScores: AnyObject) {
    if (!incomingChallengeScores) return;

    if (!Object.keys(incomingChallengeScores).length && !existingRecord.for_test_lesson) {
        existingRecord.challenge_scores = {};
    }

    Object.keys(incomingChallengeScores).forEach(key => {
        const score = incomingChallengeScores[key];
        const currentScore = existingRecord.challenge_scores[key];
        const hasCurrentScore = key in existingRecord.challenge_scores;

        const forTestOrAssessmentLesson = existingRecord.for_test_lesson || existingRecord.for_assessment_lesson;
        const shouldUpdateScore =
            (forTestOrAssessmentLesson && !hasCurrentScore) || (!forTestOrAssessmentLesson && score !== currentScore);

        if (shouldUpdateScore) {
            existingRecord.challenge_scores[key] = score;
        }
    });
}

async function saveStreamProgress(frontRoyalStore: FrontRoyalStore, streamProgress: StreamProgressRecord) {
    const meta = {} as {
        lesson_streams_progress: StreamProgressRecord;
        append_to_favorite_lesson_stream_locale_packs: { id: string }[];
    };

    const record =
        (await frontRoyalStore.retryOnHandledError(db =>
            db.streamProgress
                .where('[user_id+locale_pack_id]')
                .equals([streamProgress.user_id, streamProgress.locale_pack_id])
                .first(),
        )) ||
        ({
            locale_pack_id: streamProgress.locale_pack_id,
            user_id: streamProgress.user_id,
        } as StreamProgressRecord);

    if (!record.created_at) {
        record.created_at = new Date().getTime() / 1000;
        meta.append_to_favorite_lesson_stream_locale_packs = [
            {
                id: record.locale_pack_id,
            },
        ];
    }

    record.synced_to_server = 0;
    record.fr_version = getVersionId();

    (['official_test_score', 'lesson_bookmark_id', 'completed_at', 'complete'] as const).forEach(key => {
        // FIXME: see related comment above in saveLessonAndStreamProgress
        record[key as 'complete'] = streamProgress[key as 'complete'];
    });

    await frontRoyalStore.retryRequestOnHandledError('saveProgress', {
        table: 'streamProgress',
        records: [record],
    });

    meta.lesson_streams_progress = record;

    return meta;
}

export default async function lessonProgressRequestInterceptor(
    config: IRequestConfig,
    $injector: auto.IInjectorService,
) {
    const isSave = ['post', 'put'].includes(config.method.toLowerCase());
    if (!isSave) return null;

    // Don't intercept when we're trying to send results to the server
    if (config?.data?.get && JSON.parse(config.data.get('meta'))?.flushing_front_royal_store) {
        return null;
    }

    const isLessonProgressCall = config.url.match('api/lesson_progress.json');
    if (isLessonProgressCall) {
        return () =>
            handleRequest({
                lessonProgress: config.data.record,
                config,
                $injector,
            });
    }

    return null;
}
