import { type IHttpService, type IRequestConfig } from 'angular';
import {
    type ProjectProgressRecord,
    type StreamProgressRecord,
    type FrontRoyalStore,
    type LessonProgressRecord,
} from 'FrontRoyalStore';
import { LESS_URGENT_THAN_NORMAL } from 'HttpQueue';

type HTTPResponse = {
    contents: { lesson_streams_progress?: StreamProgressRecord[] };
    meta: { lesson_progress: LessonProgressRecord[]; project_progress: ProjectProgressRecord[] };
};

export default async function fetchProgressForUser(
    userId: string,
    frontRoyalStore: FrontRoyalStore,
    $http: IHttpService,
    filters = {},
) {
    const response = await $http<HTTPResponse>({
        method: 'GET',
        url: `${window.ENDPOINT_ROOT}/api/lesson_streams_progress.json`,
        params: {
            filters: {
                user_id: userId,
                ...filters,
            },
            include_lesson_progress: true,
            include_project_progress: true,
        },
        headers: {
            Accept: 'application/json',
        },
        httpQueueOptions: {
            priority: LESS_URGENT_THAN_NORMAL,
        },
    } as IRequestConfig);

    const streamProgressRecords = response.data.contents.lesson_streams_progress || [];
    const lessonProgressRecords = response.data.meta?.lesson_progress || [];
    const projectProgressRecords = response.data.meta?.project_progress || [];

    await Promise.all([
        frontRoyalStore.retryRequestOnHandledError('saveProgress', {
            table: 'streamProgress',
            records: streamProgressRecords,
        }),
        frontRoyalStore.retryRequestOnHandledError('saveProgress', {
            table: 'lessonProgress',
            records: lessonProgressRecords,
        }),
        frontRoyalStore.retryRequestOnHandledError('saveProgress', {
            table: 'projectProgress',
            records: projectProgressRecords,
        }),
    ]);

    // record the fact that we have loaded progress for this user
    await frontRoyalStore.retryRequestOnHandledError('setProgressFetchedForUser', userId);

    return {
        streamProgressRecords,
        lessonProgressRecords,
        projectProgressRecords,
    };
}
