export function bool(str: string): boolean {
    return /^true$/i.test(str);
}

export function insertAt(str: string, index: number, stringToInsert: string): string {
    if (index > 0) {
        return str.substring(0, index) + stringToInsert + str.substring(index, str.length);
    }

    return stringToInsert + str;
}

// FIXME: use camelcase package. We're importing it anyway. Note that camelcase
// gives slightly different results than the current camelize function.  Try to
// camelize a uuid to see the difference.  They disagree on whether to capitalize
// a letter after a number
export function camelize(str: string, capitalize: boolean): string {
    if (capitalize === true) {
        return str.replace(/(?:^|[-_])(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
    }
    return str.replace(/(?:[-_])(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
}

// FIXME: it is odd that this is called snake case, but by default
// it kabob-cases.
export function snakeCase(str: string, separator = '-'): string {
    const SNAKE_CASE_REGEXP = /[A-Z]/g;
    return str.replace(SNAKE_CASE_REGEXP, (letter, pos) => (pos ? separator : '') + letter.toLowerCase());
}

export function underscore(str: string): string {
    if (str === '') {
        return str;
    }
    const lowerCased = str[0].toLowerCase() + str.substr(1, str.length);
    const dashesReplaced = lowerCased.replace('-', '_');
    return dashesReplaced.replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`);
}

export function padNumber(n: number, width: number, z = '0'): string {
    const nStr = `${n}`;
    return nStr.length >= width ? nStr : new Array(width - nStr.length + 1).join(z) + n;
}

export function humanize(str: string): string {
    return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, m => m.toUpperCase());
}

export function titleize(sentence: string): string {
    if (!sentence.split) return sentence;
    const _titleizeWord = (string: string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    const result: string[] = [];
    sentence.split(' ').forEach(w => {
        result.push(_titleizeWord(w));
    });
    return result.join(' ');
}
