import type Dexie from 'dexie';
import { minKey, maxKey } from 'DexieHelper';
import {
    type ProjectProgressRecord,
    type BookmarkedStream,
    type LessonProgressRecord,
    type StreamProgressRecord,
} from 'FrontRoyalStore/FrontRoyalStore.types';

type ProgressRecord = BookmarkedStream | LessonProgressRecord | StreamProgressRecord | ProjectProgressRecord;

export default function allForUser<T extends ProgressRecord>(table: Dexie.Table<T>, userId: string) {
    const key = table.name === 'projectProgress' ? '[user_id+requirement_identifier]' : '[user_id+locale_pack_id]';

    return table.where(key).between([userId, minKey], [userId, maxKey]);
}
