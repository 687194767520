import { type AnyObject } from '@Types';
import { type auto } from 'angular';
import { type FrontRoyalStore } from 'FrontRoyalStore';
import fetchProgressForUser from './fetchProgressForUser';

export default async function ensureUserProgressFetched(userId: string, $injector: auto.IInjectorService) {
    const frontRoyalStore = $injector.get<FrontRoyalStore>('frontRoyalStore');
    const allProgressForUser = (await frontRoyalStore.retryRequestOnHandledError('allProgressForUser', userId))!;

    let streamProgressRecords = [];
    let lessonProgressRecords = [];
    let projectProgressRecords = [];

    if (allProgressForUser.fetched) {
        ({ streamProgressRecords, lessonProgressRecords, projectProgressRecords } = allProgressForUser);
    } else {
        const $http = $injector.get('$http');
        ({ streamProgressRecords, lessonProgressRecords, projectProgressRecords } = await fetchProgressForUser(
            userId,
            frontRoyalStore,
            $http,
        ));
    }

    const bookmarkedStreams = (await frontRoyalStore.retryRequestOnHandledError('bookmarkedStreamsForUser', userId))!;

    const favoriteStreamsSet = bookmarkedStreams.reduce<AnyObject<boolean>>(
        (obj, stream) => ({ ...obj, [stream.locale_pack_id]: true }),
        {},
    );

    return {
        streamProgress: streamProgressRecords,
        lessonProgress: lessonProgressRecords,
        projectProgress: projectProgressRecords,
        favoriteStreamsSet,
    };
}
