import { type Subscription, StripeStatus } from './Subscription.types';

export const subscriptionStub: Subscription = {
    id: '6d0e0c29-ebf1-46e7-b74c-fea24b32965b',
    cancelAtPeriodEnd: false,
    currentPeriodEnd: 1684915200,
    stripePlanId: 'price_1LunJu2nDm85BVWR1u7sLcUO',
    stripeStatus: StripeStatus.trialing,
    tuitionContractId: '37bb97ba-662c-5c5c-8efb-385832b323a5',
};
