export default function isCurrentUserUpdateApiCall(config, $injector) {
    const $rootScope = $injector.get('$rootScope');
    const record = config.data instanceof FormData ? JSON.parse(config.data.get('record')) || {} : config.data?.record;
    return (
        config.method === 'PUT' &&
        config.url.includes('/api/users.json') &&
        record?.id &&
        $rootScope.currentUser?.id &&
        record.id === $rootScope.currentUser.id
    );
}
