import { type CurrentUserRecord, type FrontRoyalStore } from 'FrontRoyalStore';
import { getUserObjectForSave } from 'Users';

async function flushCurrentUserRecord(currentUserRecord: CurrentUserRecord, $injector: ng.auto.IInjectorService) {
    const $http = $injector.get('$http');
    const $window = $injector.get('$window');

    const formData = new FormData();
    const currentUserObject = getUserObjectForSave(currentUserRecord, 'base');
    formData.append('record', JSON.stringify(currentUserObject));
    formData.append('meta', JSON.stringify({ flushing_front_royal_store: true }));

    const request = {
        method: 'PUT',
        url: `${$window.ENDPOINT_ROOT}/api/users.json`,
        data: formData,
        headers: {
            Accept: 'application/json',

            // We have to set Content-type to undefined here.  When we
            // do that, the content-type ends up getting set to
            // multipart form with an appropriate boundary.  Otherwise
            // it ends up being application/json.  I guess maybe
            // $http is doing that
            'Content-type': undefined,
        },
    };

    // See currentUserInterceptor to see how we handle this request and the response.
    return $http(request);
}

export default async function flushStoredCurrentUser($injector: ng.auto.IInjectorService) {
    const frontRoyalStore = $injector.get<FrontRoyalStore>('frontRoyalStore');
    const currentUserRecord = await frontRoyalStore.getCurrentUser();

    if (currentUserRecord?.synced_to_server === 0) {
        const response = await flushCurrentUserRecord(currentUserRecord, $injector);
        await frontRoyalStore.updateCurrentUser(currentUserRecord.id, { synced_to_server: 1 });
        return response;
    }

    return null;
}
