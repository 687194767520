import { saveAs } from 'file-saver';

import { type FontConfig } from 'ShareableGraphics/ShareableGraphics.types';
import { type FrontRoyalWindow } from 'FrontRoyalWindow';
import { downloadSupported, findAndCloneSVG, getSVGAsPNG, loadFontAssets, shareSupported } from './downloadHelpers';

declare let window: FrontRoyalWindow;
/*
    To save or share the SVG as a PNG, we need to do the following:
        1. Load up all non web-safe fonts and encode them to base64.
        2. Embed the base64 encoded fonts in the SVG.
        3. Convert the SVG to a data URL and set it as the `src` on a new Image.
        4. Once the image has loaded, draw the image to a canvas element.
        5. Convert canvas > blob > PNG image and either
            a) save it to the file system or
            b) share it with native mobile share screen via Cordova plugin

    NOTE: Part of this flow has been modeled after https://github.com/tsayen/dom-to-image.
*/
export async function downloadOrShareSVG({
    setDownloading,
    containerElem,
    fileName,
    width,
    height,
    fontConfigs,
}: {
    setDownloading?: (downloading: boolean) => void;
    containerElem: Element;
    fileName: string;
    width: number;
    height: number;
    fontConfigs?: FontConfig[];
}) {
    setDownloading?.(true);

    // clone the SVG and use the clone for the rest of the work
    // so we don't accidentally mess with the SVG in the UI.
    const clone = findAndCloneSVG(containerElem, width, height);

    if (fontConfigs) await loadFontAssets(fontConfigs, clone);

    const png = await getSVGAsPNG(clone, width, height);
    const fileNameWithExt = `${fileName}.png`;

    if (downloadSupported()) {
        saveAs(png.blob, fileNameWithExt);
        setDownloading?.(false);
    } else if (await shareSupported()) {
        window.plugins.socialsharing!.share(null, fileNameWithExt, png.image.src, null);
        setDownloading?.(false);
    }
}

export function getWrappedTextLines(text: string, limit: number) {
    const words = text.split(' ');
    const lines = [words.shift()!];

    while (words[0] && lines[0].length + words[0].length + 1 <= limit) {
        lines[0] = lines[0].concat(' ', words.shift()!);
    }

    lines.push(words.join(' '));

    return lines;
}
