/* eslint-disable func-names */

angular.module('SuperModel', ['AClassAbove']).provider('SuperModel', function () {
    this.$get = [
        'AClassAbove',
        'SuperModel.Callbacks',
        function (Class, ...plugins) {
            const SuperModel = Class.subclass(function () {
                angular.forEach(plugins, mixins => {
                    this.extend(mixins.classMixin || {});
                    this.include(mixins.instanceMixin || {});
                    if (mixins.included) {
                        mixins.included(this);
                    }
                });

                return {};
            });

            return SuperModel;
        },
    ];
});
