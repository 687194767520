/* eslint-disable no-await-in-loop */
import { OnetrustCookieHelper } from 'Onetrust';

const sleep = async ms =>
    new Promise(resolve => {
        setTimeout(resolve, ms);
    });

async function flushBatch($injector) {
    const $http = $injector.get('$http');
    const $window = $injector.get('$window');
    const frontRoyalStore = $injector.get('frontRoyalStore');

    // Pull the oldest 100 events from the db
    const eventsToFlush = await frontRoyalStore.retryOnHandledError(
        db => db.events.orderBy('client_utc_timestamp').limit(100).toArray(),
        {
            // we might flush the store after it has been disabled
            allowDisabledFrontRoyalStore: true,
        },
    );

    if (!eventsToFlush.some(Boolean)) return false;

    // The server is only able to handle bundles of events from a single
    // page load.  Maybe we should fix this on the server, but for now
    // at least trying to keep changes isolated to the FrontRoyalStore
    // as much as possible
    const events = eventsToFlush
        .filter(event => event.page_load_id === eventsToFlush[0].page_load_id)
        .map(event => {
            // Eventually, we should remove the buffering from EventLogger.  The FrontRoyalStore
            // makes it unnecessary.  But, leaving that as-is for now to try to restrict
            // changes to store layer as much as possible.
            event.buffered_time += new Date().getTime() / 1000 - event.saved_to_front_royal_store_at;
            delete event.saved_to_front_royal_store_at;
            return event;
        });

    const formData = new FormData();
    formData.append(
        'record',
        JSON.stringify({
            events,
        }),
    );
    formData.append(
        'meta',
        JSON.stringify({
            flushing_front_royal_store: true,
            consent_disabled_integrations: OnetrustCookieHelper.getDisabledIntegrationsObject(),
        }),
    );

    // FIXME: Should we move this to TinyEventLogger?
    const request = {
        method: 'POST',
        url: `${$window.ENDPOINT_ROOT}/api/event_bundles.json`,
        data: formData,
        headers: {
            Accept: 'application/json',

            // We have to set Content-type to undefined here.  When we
            // do that, the content-type ends up getting set to
            // multipart form with an appropriate boundary.  Otherwise
            // it ends up being application/json.  I guess maybe
            // $http is doing that
            'Content-type': undefined,
        },
    };

    await $http(request);

    await frontRoyalStore.retryOnHandledError(db =>
        db.events
            .where('id')
            .anyOf(events.map(ev => ev.id))
            .delete(),
    );

    return true;
}

export default async function flushStoredEvents($injector) {
    const ConfigFactory = $injector.get('ConfigFactory');
    const flushEventsDelayMs = ConfigFactory.getSync().flushEventsDelayMs() ?? 750;

    let hasMoreEvents = true;

    while (hasMoreEvents) {
        hasMoreEvents = await flushBatch($injector);
        // Cloudflare will drop requests if a user sends more than 200 requests per minute (See https://trello.com/c/fkbN92JO).
        // In order to stay safely below that, we delay between each request.
        await sleep(flushEventsDelayMs);
    }
}
