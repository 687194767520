import { scholarshipStubZenith200, cumulativeScholarshipStub200 } from 'Scholarship';
import { subscriptionStub } from 'Subscription';
import { monthlyPlan, annualPlan } from 'TuitionPlan';
import { type TuitionContract, TuitionContractStatus } from './TuitionContract.types';

export const stubTuitionContractMonthlyPlan: TuitionContract = {
    id: '80a07a35-3db5-48ea-9c47-51f7ef31d65a',
    agreedAt: 0,
    scholarships: [scholarshipStubZenith200],
    cumulativeScholarship: cumulativeScholarshipStub200,
    netRequiredPayment: 10500,
    refundableTuitionAmount: 0,
    netTransactionAmount: 0,
    netTuitionAmount: 0,
    netPaymentFeeAmount: 0,
    tuitionPlan: monthlyPlan,
    tuitionBenefitAdministrator: null,
    status: TuitionContractStatus.Open,
    inPaymentGracePeriod: false,
    enrollmentAgreementId: 'd6ba087a-6cd5-4f01-a2af-3c843b780a36',
    tilaDisclosureId: '6ae80c68-7fb5-4fcf-9150-808c69d87743',
    subscription: null,
};

export const stubTuitionContractMonthlyPlanWithSubscription: TuitionContract = {
    id: '80a07a35-3db5-48ea-9c47-51f7ef31d65b',
    agreedAt: 0,
    scholarships: [scholarshipStubZenith200],
    cumulativeScholarship: cumulativeScholarshipStub200,
    netRequiredPayment: 10500,
    refundableTuitionAmount: 0,
    netTransactionAmount: 0,
    netTuitionAmount: 0,
    netPaymentFeeAmount: 0,
    tuitionPlan: monthlyPlan,
    tuitionBenefitAdministrator: null,
    status: TuitionContractStatus.Open,
    inPaymentGracePeriod: false,
    enrollmentAgreementId: 'd6ba087a-6cd5-4f01-a2af-3c843b780a36',
    tilaDisclosureId: '6ae80c68-7fb5-4fcf-9150-808c69d87743',
    subscription: { ...subscriptionStub, tuitionContractId: '80a07a35-3db5-48ea-9c47-51f7ef31d65b' },
};

export const stubTuitionContractAnnualPlanWithSubscription: TuitionContract = {
    id: '80a07a35-3db5-48ea-9c47-51f7ef31d65c',
    agreedAt: 0,
    scholarships: [scholarshipStubZenith200],
    cumulativeScholarship: cumulativeScholarshipStub200,
    netRequiredPayment: 9800,
    refundableTuitionAmount: 0,
    netTransactionAmount: 0,
    netTuitionAmount: 0,
    netPaymentFeeAmount: 0,
    tuitionPlan: annualPlan,
    tuitionBenefitAdministrator: null,
    status: TuitionContractStatus.Open,
    inPaymentGracePeriod: false,
    enrollmentAgreementId: 'd6ba087a-6cd5-4f01-a2af-3c843b780a36',
    tilaDisclosureId: 'f4a9beef-9020-4d13-b353-73ba469b6f7e',
    subscription: { ...subscriptionStub, tuitionContractId: '80a07a35-3db5-48ea-9c47-51f7ef31d65c' },
};
