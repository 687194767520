export enum StripeStatus {
    active = 'active',
    incomplete = 'incomplete',
    pastDue = 'past_due',
    trialing = 'trialing',
}

export interface Subscription {
    id: string;
    cancelAtPeriodEnd: boolean;
    currentPeriodEnd: number;
    stripePlanId: string;
    stripeStatus: StripeStatus;
    tuitionContractId: string | null;
}
