import { type Scholarship, type CumulativeScholarship } from 'Scholarship';
import { type Subscription } from 'Subscription';
import { type TuitionPlan } from 'TuitionPlan';

export enum TuitionContractStatus {
    Open = 'open',
    Canceled = 'canceled',
    Complete = 'complete',
}

export interface TuitionContract {
    id: string;
    agreedAt: number;
    scholarships: Scholarship[];
    cumulativeScholarship: CumulativeScholarship | null;
    netRequiredPayment: number;
    refundableTuitionAmount: number;
    netTransactionAmount: number;
    netTuitionAmount: number;
    netPaymentFeeAmount: number;
    tuitionPlan: TuitionPlan;
    tuitionBenefitAdministrator: string | null;
    status: TuitionContractStatus;
    inPaymentGracePeriod: boolean;
    enrollmentAgreementId: string | null;
    tilaDisclosureId: string | null;
    subscription: Subscription | null;
}
