import { TuitionPlanFrequency } from 'TuitionPlan';
import { type TuitionContract, TuitionContractStatus } from './TuitionContract.types';

export function tuitionContractComplete(tuitionContract: TuitionContract | null) {
    return tuitionContract?.status === TuitionContractStatus.Complete;
}

export function tuitionContractIsForOnetimePurchase(tuitionContract: TuitionContract | null) {
    return tuitionContract?.tuitionPlan.frequency === TuitionPlanFrequency.Once;
}

export function totalRemainingTuition(tuitionContract: TuitionContract | null) {
    if (!tuitionContract) return 0;

    // If someone has a complete contract, we view them as having fulfilled their
    // payment obligations.
    if (tuitionContractComplete(tuitionContract)) return 0;

    return Math.max(tuitionContract.netRequiredPayment - tuitionContract.netTuitionAmount, 0);
}

export function paymentPerInterval(tuitionContract: TuitionContract | null) {
    if (!tuitionContract) return 0;

    // In practice, we shouldn't get here, but TuitionPlans for full scholarship
    // have `null` numIntervals.
    if (tuitionContract.tuitionPlan.numIntervals === null) return 0;

    // Note that we have validations in Rails that enforce numIntervals will be positive for
    // every plan other than the full scholarship plan, so we don't need to worry about dividing
    // by zero here.
    return tuitionContract.netRequiredPayment / tuitionContract.tuitionPlan.numIntervals;
}

// When using Stripe Checkout 2.0 + Checkout Sessions, onetime purchases do not automatically
// get invoices.
// See also https://stripe.com/docs/payments/checkout/post-payment-invoices
// and @session_params[:invoice_creation] in StripeWrappers::Checkout::Session
export function tuitionContractLikelyHasNoInvoices(tuitionContract: TuitionContract | null): boolean {
    if (tuitionContract) {
        return (
            // FIXME: after we are enrolled in the post-payment invoices for onetime purchases
            // beta, we should change the closing guard clause for these date checks. This should
            // return false for anyone that has a TuitionContract with an agreedAt after we
            // set @session_params[:invoice_creation] in StripeWrappers::Checkout::Session.
            new Date(tuitionContract.agreedAt * 1000) >= new Date('2022/11/10') &&
            new Date(tuitionContract.agreedAt * 1000) < new Date('2099/11/10') &&
            tuitionContractComplete(tuitionContract) &&
            tuitionContractIsForOnetimePurchase(tuitionContract)
        );
    }
    return false;
}
