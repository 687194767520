import labGrotesqueRegular from 'fonts/lab-grotesque/LabGrotesque-Regular.woff';
import labGrotesqueMedium from 'fonts/lab-grotesque/LabGrotesque-Medium.woff';
import labGrotesqueBold from 'fonts/lab-grotesque/LabGrotesque-Bold.woff';
import montserratMedium from 'fonts/montserrat/Montserrat-Medium.ttf';
import montserratSemiBold from 'fonts/montserrat/Montserrat-SemiBold.ttf';
import montserratBold from 'fonts/montserrat/Montserrat-Bold.woff';
import { type FontConfig } from './ShareableGraphics.types';

export const SVG_VIEWBOX_WIDTH = 600;
export const SVG_VIEWBOX_HEIGHT = 928;

export const fontConfigs: FontConfig[] = [
    { fontFamily: 'Lab Grotesque Regular', assetPath: labGrotesqueRegular, mimeType: 'font/woff', format: 'woff' },
    { fontFamily: 'Lab Grotesque Medium', assetPath: labGrotesqueMedium, mimeType: 'font/woff', format: 'woff' },
    { fontFamily: 'Lab Grotesque Bold', assetPath: labGrotesqueBold, mimeType: 'font/woff', format: 'woff' },
    { fontFamily: 'Montserrat Medium', assetPath: montserratMedium, mimeType: 'font/ttf', format: 'truetype' },
    { fontFamily: 'Montserrat SemiBold', assetPath: montserratSemiBold, mimeType: 'font/ttf', format: 'truetype' },
    { fontFamily: 'Montserrat Bold', assetPath: montserratBold, mimeType: 'font/woff', format: 'woff' },
];
