import { type auto } from 'angular';
import { type FrontRoyalStore, type LessonProgressRecord, type StreamProgressRecord } from 'FrontRoyalStore';
import { mergeIncomingChanges } from 'LessonProgress';

type HTTPResponse = {
    contents: { lesson_progress: LessonProgressRecord[] };
    meta: { stream_progress_records: StreamProgressRecord[] };
};

async function flushProgressForLessonAndSaveResults(record: LessonProgressRecord, injector: auto.IInjectorService) {
    const $http = injector.get('$http');
    const $window = injector.get('$window');
    const frontRoyalStore = injector.get<FrontRoyalStore>('frontRoyalStore');

    const streamProgressKeys =
        record.linked_stream_locale_pack_ids?.map(localePackId => [record.user_id, localePackId]) ?? [];

    const streamProgressRecords = await frontRoyalStore.retryOnHandledError(
        db => db.streamProgress.where('[user_id+locale_pack_id]').anyOf(streamProgressKeys).toArray(),
        {
            // we might flush the store after it has been disabled
            allowDisabledFrontRoyalStore: true,
        },
    );

    const formData = new FormData();
    formData.append('record', JSON.stringify(record));
    formData.append(
        'meta',
        JSON.stringify({
            stream_progress_records: streamProgressRecords,
            flushing_front_royal_store: true,
        }),
    );

    const request = {
        method: record.id ? 'PUT' : 'POST',
        url: `${$window.ENDPOINT_ROOT}/api/lesson_progress.json`,
        data: formData,
        headers: {
            Accept: 'application/json',

            // We have to set Content-type to undefined here.  When we
            // do that, the content-type ends up getting set to
            // multipart form with an appropriate boundary.  Otherwise
            // it ends up being application/json.  I guess maybe
            // $http is doing that
            'Content-type': undefined,
        },
    };

    const { data } = await $http<HTTPResponse>(request);
    const incomingAttrs = mergeIncomingChanges(record, data.contents.lesson_progress[0]);
    const lessonProgressPromises = [
        frontRoyalStore.retryRequestOnHandledError('saveProgressAttrsFromServer', {
            table: 'lessonProgress',
            record,
            incomingAttrs,
        }),
    ];

    data.meta.stream_progress_records?.forEach(streamProgressAttrs => {
        const streamProgressRecord = streamProgressRecords.find(
            ({ locale_pack_id }) => streamProgressAttrs.locale_pack_id === locale_pack_id,
        );

        if (!streamProgressRecord) throw new Error('No stream progress record found.');

        lessonProgressPromises.push(
            frontRoyalStore.retryRequestOnHandledError('saveProgressAttrsFromServer', {
                table: 'streamProgress',
                record: streamProgressRecord,
                incomingAttrs: streamProgressAttrs,
            }),
        );
    });

    return Promise.all(lessonProgressPromises);
}

export default async function flushStoredLessonProgress(injector: auto.IInjectorService) {
    const frontRoyalStore = injector.get<FrontRoyalStore>('frontRoyalStore');

    const lessonProgressRecords = await frontRoyalStore.retryOnHandledError(
        db => db.lessonProgress.where('synced_to_server').equals(0).toArray(),
        {
            // we might flush the store after it has been disabled
            allowDisabledFrontRoyalStore: true,
        },
    );

    const promises = lessonProgressRecords.map(record => flushProgressForLessonAndSaveResults(record, injector));

    return Promise.all(promises);
}
