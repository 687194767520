export enum ProgramAchievementGraphicDesignKey {
    quanticCoral = 'quanticCoral',
    quanticPurple = 'quanticPurple',
    quanticDarkBlue = 'quanticDarkBlue',
    valarBlue = 'valarBlue',
    valarPurple = 'valarPurple',
    valarGreen = 'valarGreen',
}

export enum ProgramAchievementGraphicKey {
    accepted = 'accepted',
    graduated = 'graduated',
}

type RGBA = {
    r: number;
    g: number;
    b: number;
    a?: number;
};

export type AchievementDesign = {
    ringRGBA: RGBA;
    bgGradient: {
        startColor: { hex: string; rgba: RGBA };
        endColor: { hex: string; rgba: RGBA };
    };
};

export type QuanticAchievementDesign = AchievementDesign & {
    gradient: {
        stop1Color: string;
        stop2Color: string;
        stop3Color: string;
    };
    logoBg: string;
};

export type ValarAchievementDesign = AchievementDesign & {
    primaryColor: {
        hex: string;
        rgba: RGBA;
    };
};

export type ProgramAchievementGraphicDesign = QuanticAchievementDesign | ValarAchievementDesign;

export type FontConfig = {
    fontFamily: string;
    assetPath: string;
    mimeType: string;
    format: string;
};
