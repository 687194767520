import { type FontConfig } from 'ShareableGraphics/ShareableGraphics.types';
import { type FrontRoyalWindow } from 'FrontRoyalWindow';
import $ from 'jquery';

declare let window: FrontRoyalWindow;

export function findAndCloneSVG(containerElem: Element, width: number, height: number) {
    const svg = $(containerElem).find('svg')[0];
    const clone = svg.cloneNode(true) as Element;

    // We need to set the width and height on the SVG so that it actually
    // shows up when it gets drawn to the canvas. Otherwise, the canvas
    // appears blank in certain browsers like FF.
    clone.setAttribute('width', width.toString());
    clone.setAttribute('height', height.toString());
    return clone;
}

export async function loadFontAssets(fonts: FontConfig[], svg: Element) {
    if (!fonts?.length) return;

    const fontRequests = fonts.map(fontConfig =>
        fetch(new Request(window.ENDPOINT_ROOT + fontConfig.assetPath))
            .then(resp => resp.arrayBuffer())
            .catch(() => null),
    );

    const fontResponseData = await Promise.all(fontRequests);

    const fontsAsBase64: { [fontFamily: string]: string } = fonts.reduce(
        (obj, fontConfig, i) => ({ ...obj, [fontConfig.fontFamily]: convertFontToBase64(fontResponseData[i]!) }),
        {},
    );

    // Add link[rel="preload"] elements for the base64 fonts to the document
    // to ensure they are loaded
    fonts.forEach(fontConfig => {
        const linkElem = getLinkElemForBase64FontFace(fontConfig, fontsAsBase64[fontConfig.fontFamily]);
        document.head.appendChild(linkElem);
    });
    await document.fonts.ready;

    // Embed the base64-url @font-face declarations in the SVG.
    const styleElem = document.createElement('style');
    fonts.forEach(fontConfig => {
        const fontFaceTextNode = getTextNodeForFontFaceCSSRule(fontConfig, fontsAsBase64[fontConfig.fontFamily]);
        styleElem.appendChild(fontFaceTextNode);
    });
    $(svg).prepend(styleElem);
}

// We serialize the SVG in preparation for using the serialized
// version in an image data URL. We also replace some XHTML that
// prevents the image from rendering properly with some escaped
// values that won't cause the image to break.
export function getSVGDataUrl(svg: Element) {
    const serializedSVG = new window.XMLSerializer().serializeToString(svg);
    const escapedXHTML = serializedSVG.replace(/#/g, '%23').replace(/\n/g, '%0A');
    return `data:image/svg+xml;charset=utf-8,${escapedXHTML}`;
}

export async function getSVGAsPNG(clone: Element, width: number, height: number) {
    const dataURL = getSVGDataUrl(clone);

    // We render the SVG inside of an image so that we can later
    // draw the image to a canvas, which we can then save to the
    // file system.

    // We generate an initial throwaway image because of a bug in Safari
    // where no fonts show up in the final image until the second time
    await getImage(dataURL);
    const image = await getImage(dataURL);
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')!.drawImage(image, 0, 0);

    // Convert the canvas data to a Blob so that we can
    // save it to the file system as a PNG.
    // See https://github.com/tsayen/dom-to-image.
    const binaryString = window.atob(canvas.toDataURL().split(',')[1]);
    const binaryStringLength = binaryString.length;
    const binaryArray = new Uint8Array(binaryStringLength);
    for (let i = 0; i < binaryStringLength; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([binaryArray], {
        type: 'image/png',
    });
    const pngDataUrl = await blobToDataURL(blob);
    const pngImage = await getImage(pngDataUrl);

    return { blob, image: pngImage };
}

export function getImage(dataUrl: string): Promise<HTMLImageElement> {
    return new Promise(res => {
        const image = new Image();
        image.src = dataUrl;
        image.onload = () => res(image);
    });
}

export function getFontDataUrl(fontConfig: FontConfig, base64EncodedFont: string) {
    return `data:${fontConfig.mimeType};charset=utf-8;base64,${base64EncodedFont}`;
}

export function getFontFaceCSSRule(fontConfig: FontConfig, base64EncodedFont: string) {
    const dataUrl = getFontDataUrl(fontConfig, base64EncodedFont);
    return `@font-face { font-family: '${fontConfig.fontFamily}'; src: url(${dataUrl}) format('${fontConfig.format}'); font-style: normal; font-display: block; }`;
}

export function getTextNodeForFontFaceCSSRule(fontConfig: FontConfig, base64EncodedFont: string) {
    const fontFaceRule = getFontFaceCSSRule(fontConfig, base64EncodedFont);
    return document.createTextNode(fontFaceRule);
}

export function getLinkElemForBase64FontFace(fontConfig: FontConfig, base64EncodedFont: string) {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'font';
    link.type = fontConfig.mimeType;
    link.href = getFontDataUrl(fontConfig, base64EncodedFont);
    return link;
}

export function convertFontToBase64(data: ArrayBuffer) {
    // NOTE: The for-loop could really be condensed down to just:
    //      const binaryString = String.fromCharCode.apply(null, binaryArray);
    // but since the binaryArray is so long, it causes a max call stack exceeded error
    // in certain browsers like Safari.
    const binaryArray = new Uint8Array(data);
    let binaryString = '';
    for (let i = 0; i < binaryArray.length; i++) {
        binaryString += String.fromCharCode(binaryArray[i]);
    }

    return window.btoa(binaryString);
}

export function blobToDataURL(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result as string);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error('Read aborted'));
        reader.readAsDataURL(blob);
    });
}

/*
    The user can share the graphic on Cordova via the social sharing plugin.
    The user can download the graphic on the web.
*/

export async function shareSupported(): Promise<boolean> {
    return new Promise(resolve => {
        if (!window.CORDOVA || !window.plugins?.socialsharing) {
            resolve(false);
        }
        window.plugins.socialsharing!.available(resolve);
    });
}

export function downloadSupported() {
    return !window.CORDOVA;
}
