import { TuitionPlanFrequency, type TuitionPlan } from 'TuitionPlan';

export function isOneTimePlan(tuitionPlanId: string | null | undefined, tuitionPlans: TuitionPlan[]) {
    if (!tuitionPlanId) return false;
    const tuitionPlan = tuitionPlans.find(plan => plan.id === tuitionPlanId);
    return tuitionPlan?.frequency === TuitionPlanFrequency.Once;
}

export function availableTuitionPlanForFrequency(availableTuitionPlans: TuitionPlan[], frequency: string) {
    return availableTuitionPlans?.find(tuitionPlan => tuitionPlan.frequency === frequency);
}
