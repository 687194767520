import buildApiResponse from 'buildApiResponse';
import { forEach } from 'lodash/fp';

async function handleRequest({ eventBundle, config, $injector }) {
    const frontRoyalStore = $injector.get('frontRoyalStore');
    await saveEvents($injector, eventBundle);
    await frontRoyalStore.flush();

    return buildApiResponse(config, {});
}
async function saveEvents($injector, eventBundle) {
    const frontRoyalStore = $injector.get('frontRoyalStore');
    // using each instead of forEach because each returns the array
    await frontRoyalStore.retryOnHandledError(db =>
        db.events.bulkPut(
            forEach(event => {
                event.saved_to_front_royal_store_at = new Date().getTime() / 1000;
            })(eventBundle.events),
        ),
    );
}

export default async function eventRequestInterceptor(config, $injector) {
    const isSave = ['post', 'put'].includes(config.method.toLowerCase());
    if (!isSave) {
        return null;
    }

    const isEventSaveCall = config.url.match('api/event_bundles.json');

    // Don't intercept when we're trying to send results to the server
    if (isEventSaveCall && config?.data?.get && JSON.parse(config.data.get('meta'))?.flushing_front_royal_store) {
        return null;
    }

    // Some requests send event data along in the meta.  This was intended to create
    // atomicity between, for example, lesson progress saves and event saves.  In switching
    // to using the FrontRoyalStore, we are willing to give up a tiny bit on that
    // guarantee of atomicity, since we will be saving progress and events in separate
    // http requests when we flush.
    let eventBundle;
    if (config?.data?.meta?.event_bundle) {
        eventBundle = config.data.meta.event_bundle;
        delete config.data.meta.event_bundle;
        saveEvents($injector, eventBundle);
        return null;
    }

    if (isEventSaveCall) {
        return () =>
            handleRequest({
                eventBundle: config.data.record,
                config,
                $injector,
            });
    }

    return null;
}
